// GENERATED BY 'yarn svgs', DO NOT EDIT

/* eslint-disable */
import * as React from "react";

export const raw = `
<svg xmlns="http://www.w3.org/2000/svg" width="284.5" height="160" viewBox="0 0 284.5 160">
  <g id="Circles_-_Navigation_Menu" data-name="Circles - Navigation Menu" transform="translate(24.5 20.449)">
    <circle id="Oval_Copy_5" data-name="Oval Copy 5" cx="29" cy="29" r="29" transform="translate(198 10.5)" fill="none" stroke="#a19884" stroke-miterlimit="10" stroke-width="8" opacity="0.1"/>
    <circle id="Oval_Copy_6" data-name="Oval Copy 6" cx="14" cy="14" r="14" transform="translate(156 68.916)" fill="none" stroke="#a19884" stroke-miterlimit="10" stroke-width="5" opacity="0.1"/>
    <circle id="Oval" cx="72.5" cy="72.5" r="72.5" transform="translate(-17 -12.949)" fill="none" stroke="#a19884" stroke-miterlimit="10" stroke-width="15" opacity="0.1"/>
  </g>
</svg>

`;

type Props = {
  className?: string;
};

const IconCirclesNavigationMenu = ({ className }: Props) => (
  <span className={className} dangerouslySetInnerHTML={{ __html: raw }} />
);

export default IconCirclesNavigationMenu;
