import * as React from "react";
import classnames from "classnames";

import IconCirclesNavigationMenu from "@/icons/IconCirclesNavigationMenu";
import BackgroundCircles from "@/modules/account/components/BackgroundCircles";
import Layout from "@/enums/layout";

type Props = {
  className?: string;
  title: React.ReactNode;
  actionLeft?: React.ReactNode;
  actionRight?: React.ReactNode;
  children?: React.ReactNode;
};

export default function Navbar({ className, title, actionLeft, actionRight, children }: Props) {
  return (
    <nav
      id={Layout.NAVBAR}
      className={classnames(className, "relative min-h-16 w-screen overflow-hidden")}
    >
      <div className="absolute inset-0 bg-product z-background" />

      <BackgroundCircles className="scale-120">
        <IconCirclesNavigationMenu />
      </BackgroundCircles>

      <div className="h-16 w-full p-4 grid grid-cols-3 gap-4 text-ink-product">
        <div className="flex items-center justify-start">{actionLeft}</div>
        <div className="flex items-center justify-center whitespace-nowrap">{title}</div>
        <div className="flex items-center justify-end">{actionRight}</div>
      </div>

      {children}
    </nav>
  );
}
